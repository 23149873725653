import { LoadingOverlay } from '@mantine/core';
import { showErrorToast, showSuccessToast } from '@/components/Toast';
import {
  useAddPortugalAtCredentialsMutation,
  GetSeriesDocument,
  useUpdateTenantMutation,
  useGetSeriesQuery,
} from '@/graphql/operations';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { ConnectATSubuserLevelForm, ConnectATSubuserLevelFormFields } from './SubuserLevelForm';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import { Serie_Status, Tenant_Pt_At_Credentials_Level } from '@/graphql-types';
import { useTranslation } from 'react-i18next';

export type ConnectATSubuserLevelProps = {
  onSubmitSuccess?: () => void;
};

export const ConnectATSubuserLevel = ({ onSubmitSuccess }: ConnectATSubuserLevelProps) => {
  const { t } = useTranslation();
  const tenant = useReactiveVar(tenantVar);
  const { data: seriesData, loading: seriesLoading } = useGetSeriesQuery();
  const [updateTenantMutation, { loading: updateTenantMutationLoading }] = useUpdateTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error:', message: error.message });
      return;
    },
  });

  const [addPortugalAtCredentialsMutation, { loading: addPortugalAtCredentialsMutationLoading }] =
    useAddPortugalAtCredentialsMutation({
      refetchQueries: [GetSeriesDocument],
      onError: (error) => {
        showErrorToast({ title: 'Error:', message: error.message });
        return;
      },
    });

  if (!tenant) {
    return <LoadingOverlay visible />;
  }

  const loading = addPortugalAtCredentialsMutationLoading || updateTenantMutationLoading || seriesLoading;
  const initialValues = {
    username: '',
    password: '',
    manageInvoices: 'auto',
  } satisfies ConnectATSubuserLevelFormFields;

  return (
    <>
      <LoadingOverlay visible={loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        validateOnBlur={false}
        validateOnChange
        onSubmit={async (values, { resetForm }) => {
          const hasActiveValidSerie = seriesData?.series?.some(
            (serie) => serie.status === Serie_Status.Active && serie.entries?.every((entry) => entry?.isVerified)
          );
          const addingAtCredsResult = await addPortugalAtCredentialsMutation({
            variables: {
              id: tenant.id || '',
              credentials: {
                username: String(values.username) || '',
                password: values.password || '',
                createNewSerie: !hasActiveValidSerie,
              },
              level: Tenant_Pt_At_Credentials_Level.Subuser,
            },
          });

          if (!addingAtCredsResult.data) {
            return;
          }

          const updatedTenant = await updateTenantMutation({
            variables: {
              updateTenantId: tenant.id || '',
              tenant: {
                isEnabledSyncWithAT: values.manageInvoices === 'auto',
              },
            },
          });

          if (!updatedTenant.data?.updateTenant) {
            return;
          }

          tenantVar(updatedTenant.data?.updateTenant);

          showSuccessToast({
            title: t('label.settingsUpdated'),
            message: t('label.settingsUpdatedSuccessfully'),
          });

          resetForm({ values });

          if (onSubmitSuccess) {
            onSubmitSuccess();
          }
        }}
      >
        <ConnectATSubuserLevelForm />
      </Formik>
    </>
  );
};
