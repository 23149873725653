import { ActionIcon, Button, Group, LoadingOverlay, Text /* UnstyledButton */ } from '@mantine/core';
import { useTranslation } from '@/components/I18N';
import { Form, Formik } from 'formik';
import { useUpdateTenantMutation } from '@/graphql-operations';
import { showErrorToast, showSuccessToast } from '@/components/Toast';
import { validationSchema } from './validationSchema';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { FormikCleanInput } from '@/components/FormikWrapped/new';
import IconTrashCanDark from '@/assets/icons/IconTrashCanDark.svg?react';
import LogoUpload from './parts/LogoUpload';

export const BusinessPersonalizationSettings = () => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);

  const [updateTenantMutation, { loading: updateTenantMutationLoading }] = useUpdateTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error:', message: error.message });
      return;
    },
  });

  if (!tenantObject) return <LoadingOverlay visible />;

  const initialValues = {
    iban: tenantObject.iban,
  };

  return (
    <>
      <LoadingOverlay visible={updateTenantMutationLoading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const updatedTenant = await updateTenantMutation({
            variables: {
              updateTenantId: tenantObject.id || '',
              tenant: {
                // @ts-expect-error: iban can be null (to clear it)
                iban: values.iban || null,
              },
            },
          });

          if (updatedTenant.errors) {
            // TODO: smth is wrong with types, message exists
            // showErrorToast({ title: 'Error:', message: updatedTenant.errors.message });
          } else if (updatedTenant.data?.updateTenant) {
            showSuccessToast({
              title: t('label.settingsUpdated'),
              message: t('label.settingsUpdatedSuccessfully'),
            });

            tenantVar(updatedTenant.data?.updateTenant);

            setSubmitting(false);
            resetForm({ values });
          }
        }}
      >
        {({ handleSubmit, setFieldValue, dirty, values }) => (
          <Form className="rounded-3xl bg-white-vanilla p-5 sm:mt-[34px]" autoComplete="off">
            <div className="gap-5">
              <div>
                <div className="flex justify-between">
                  <Text fw={600} className="text-[20px] text-dark-resin">
                    {t('label.customiseInvoice')}
                  </Text>
                  {/* <UnstyledButton variant="transparent" className="text-[17px] font-semibold text-moroccan-blue">
                    {t('button.label.preview')}
                  </UnstyledButton> */}
                </div>
                <span className="text-[14px] font-normal text-bordo-black">{t('label.uploadLogoAndIban')}</span>
              </div>
            </div>
            <>
              <LogoUpload />

              <div className="flex justify-between py-4">
                <Text fw={600} className="text-[20px] text-dark-resin">
                  {t('label.iban')}
                </Text>
              </div>

              <Group justify="space-between" align="top">
                {tenantObject.iban ? (
                  <FormikCleanInput
                    size="fiz-lg"
                    name="iban"
                    placeholder={t('placeholder.enterYourIban')}
                    disabled
                    rightSection={
                      <ActionIcon
                        size="xl"
                        variant="transparent"
                        onClick={async () => {
                          setFieldValue('iban', '');
                          handleSubmit();
                        }}
                      >
                        <IconTrashCanDark />
                      </ActionIcon>
                    }
                    className="grow"
                  />
                ) : (
                  <>
                    <FormikCleanInput
                      size="fiz-lg"
                      name="iban"
                      placeholder={t('placeholder.enterYourIban')}
                      className="min-w-full sm:min-w-[325px]"
                      capitalize
                      removeWhitespaces
                    />

                    {dirty && values.iban && (
                      <Button
                        type="submit"
                        size="fiz-lg"
                        className="shrink grow rounded-[20px] bg-light-brand p-5 text-[17px] font-semibold hover:bg-light-orange"
                      >
                        {t('button.label.save')}
                      </Button>
                    )}
                  </>
                )}
              </Group>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
};
