import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '../ButtonVariants';

export function SubmitButton(props: ButtonProps) {
  const { t } = useTranslation();
  const { dirty } = useFormikContext();

  return (
    <Button type="submit" fullWidth disabled={!dirty} {...props}>
      {t('button.label.save')}
    </Button>
  );
}
