import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { ActionIcon, LoadingOverlay, Text } from '@mantine/core';
import IconCheck from '@/assets/icons/IconCheck.svg?react';
import IconTrashCanRed from '@/assets/icons/IconTrashCanRed.svg?react';
import IconArrowLeftBlack from '@/assets/icons/IconArrowLeftBlack.svg?react';
import { useRemovePortugalAtCredentialsMutation } from '@/graphql-operations';
import { showErrorToast } from '@/components/Toast';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import CustomModal from '@/components/CustomModal';
import { CommunicationMethodForm } from './CommunicationMethodForm';
import { IconXboxXFilled } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { useTranslation } from 'react-i18next';
import { cn } from '@/utils';
import { ConnectWithATOptions } from './ConnectWithATOptions';

type SettingsItemProps = {
  title: string;
  description: string;
  icon?: React.ReactNode;
  actionIcon?: React.ReactNode;
  actionIconOnClick?: () => void;
  onClick?: () => void;
};

const SettingsItem = ({ title, description, icon, actionIcon, actionIconOnClick, onClick }: SettingsItemProps) => {
  return (
    <div
      className={cn('flex items-center justify-between', {
        'cursor-pointer': typeof onClick === 'function',
      })}
      onClick={onClick}
    >
      <div className="flex gap-2">
        <div className="my-auto size-[24px]">{icon}</div>
        <div className="flex flex-col">
          <span className="text-[17px] font-normal text-dark-resin">{title}</span>
          <span className="text-[14px] font-normal text-bordo-black">{description}</span>
        </div>
      </div>
      {actionIcon ? (
        <ActionIcon size="xl" variant="transparent" onClick={actionIconOnClick}>
          {actionIcon}
        </ActionIcon>
      ) : (
        <ActionIcon size="xl" variant="transparent">
          <IconArrowLeftBlack />
        </ActionIcon>
      )}
    </div>
  );
};

export const ExistingATConnection = () => {
  const tenant = useReactiveVar(tenantVar);
  const { t } = useTranslation();
  const [communicationMethodModalOpen, communicationMethodModal] = useDisclosure(false);
  const [setupATConnectionModalOpened, setupATConnectionModal] = useDisclosure(false);
  const isSmScreen = useMediaQuery('(max-width: 575px)');

  const [removePortugalATCredentialsMutation, { loading: removeCredentialsLoading }] =
    useRemovePortugalAtCredentialsMutation({
      onError: (error) => {
        showErrorToast({ title: 'Error:', message: error.message });
        return;
      },
    });

  if (!tenant) {
    return <LoadingOverlay visible />;
  }

  const credentialsVerified = !!tenant.credentials?.verified;
  const credentialsHaveUsername = !!tenant.credentials?.username;
  const credentialsHaveUserButUnverified = !credentialsVerified && credentialsHaveUsername;

  const handleRemoveATCredentials = async () => {
    const updatedTenant = await removePortugalATCredentialsMutation({
      variables: {
        removePortugalAtCredentialsId: tenant.id || '',
      },
    });

    if (!updatedTenant.data?.removePortugalATCredentials) {
      return;
    }

    tenantVar({ ...tenant, ...updatedTenant.data.removePortugalATCredentials });
  };

  const openDeleteConfirmationModal = () =>
    modals.openConfirmModal({
      modalId: 'delete-invoice-confirmation',
      title: t('modal.deleteData.title'),
      size: 375,
      radius: 24,
      padding: isSmScreen ? 16 : 20,
      centered: true,
      children: (
        <Text className="text-center text-[14px] font-normal text-dark-bronze">{t('modal.deleteData.body')}</Text>
      ),
      labels: { cancel: t('button.label.cancel'), confirm: t('button.label.delete') },
      confirmProps: {
        color: '#F2F3F6',
        variant: 'filled',
        className: 'shrink grow',
        classNames: {
          label: 'text-bright-red',
        },
      },
      cancelProps: { color: '#F2F3F6', variant: 'filled', className: 'shrink grow' },
      classNames: {
        header: 'block pb-3',
        title: 'text-[20px] font-semibold text-black-magic text-center leading-[120%]',
      },
      withCloseButton: false,
      onConfirm: handleRemoveATCredentials,
    });

  return (
    <>
      <LoadingOverlay visible={removeCredentialsLoading} className="fixed inset-0 z-50" />
      <div className="divide-y divide-fog-bronze rounded-2xl bg-white-vanilla px-4 py-3 *:py-2 first:*:pt-0 last:*:pb-0">
        <SettingsItem
          title={t('label.ATSubUser')}
          description={[tenant.taxPayerNumber, tenant.credentials?.username].join(' / ')}
          icon={
            credentialsHaveUserButUnverified ? (
              <IconXboxXFilled className="my-auto" color="#F42D2D" />
            ) : (
              <IconCheck className="my-auto" />
            )
          }
          onClick={credentialsHaveUserButUnverified ? setupATConnectionModal.open : undefined}
          actionIcon={<IconTrashCanRed />}
          actionIconOnClick={openDeleteConfirmationModal}
        />
        <SettingsItem
          title={t('label.communicationMethod')}
          description={tenant.isEnabledSyncWithAT === true ? t('label.auto') : t('label.manual')}
          onClick={communicationMethodModal.open}
        />
      </div>
      {communicationMethodModalOpen && (
        <CustomModal
          title={t('label.communicationMethod')}
          isOpened={communicationMethodModalOpen}
          handleClose={communicationMethodModal.close}
        >
          <CommunicationMethodForm onSubmitSuccess={communicationMethodModal.close} />
        </CustomModal>
      )}
      {setupATConnectionModalOpened && (
        <CustomModal
          title={t('label.atCommunication')}
          isOpened={setupATConnectionModalOpened}
          handleClose={setupATConnectionModal.close}
          size={500}
        >
          <ConnectWithATOptions onConnectionAdded={setupATConnectionModal.close} />
        </CustomModal>
      )}
    </>
  );
};
