import { Container, Group, LoadingOverlay, Paper, Space, Text, Title } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Form,Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ButtonVariants';
import { FormikInput } from '@/components/FormikWrapped';
import {
  GetSeriesDocument,
  useCreateSerieMutation,
  useUpdateSerieMutation,
  // useGetSeriesQuery,
} from '@/graphql-operations';
import { Invoice_Document_Type, MutationUpdateSerieArgs, Serie, Serie_Management_Mode } from '@/graphql-types';
import { CALENDAR_FORMAT } from '@/utils';

import validationSchema from './validationSchema';

type CreateSerieModalFormProps = {
  seriesIndex: number | undefined;
  defaultFormValues?: MutationUpdateSerieArgs['serie'];
  style?: React.CSSProperties;
  onSuccess?: (newSerie: Serie) => void;
  isEditing?: boolean;
  updateSerieId?: string;
  tenantId: string;
};

const CreateNewSerieModalForm = ({
  defaultFormValues,
  style,
  onSuccess,
  isEditing,
  updateSerieId,
  tenantId,
}: CreateSerieModalFormProps) => {
  const { t } = useTranslation();
  const [createSerieMutation, { loading, error }] = useCreateSerieMutation({
    refetchQueries: [GetSeriesDocument],
    onCompleted: (data) => {
      if (onSuccess) {
        onSuccess(data.createSerie);
      }
    },
  });
  const [updateSerieMutation, { loading: updateSerieLoading, error: updateSerieError }] = useUpdateSerieMutation({
    refetchQueries: [GetSeriesDocument],
    onCompleted: (data) => {
      if (onSuccess) {
        onSuccess(data.updateSerie);
      }
    },
  });

  const getIsDocumentTypeVerified = (documentType: Invoice_Document_Type) => {
    return defaultFormValues?.entries?.find((entry) => entry?.documentType === documentType)?.isVerified || false;
  };

  const isInvoiceDocVerified = getIsDocumentTypeVerified(Invoice_Document_Type.Invoice);
  const isDebitNoteDocVerified = getIsDocumentTypeVerified(Invoice_Document_Type.DebitNote);
  const isCreditNoteDocVerified = getIsDocumentTypeVerified(Invoice_Document_Type.CreditNote);
  const isSimplifiedInvoiceDocVerified = getIsDocumentTypeVerified(Invoice_Document_Type.SimplifiedInvoice);
  const isInvoiceReceiptDocVerified = getIsDocumentTypeVerified(Invoice_Document_Type.InvoiceReceipt);
  const isReceiptDocVerified = getIsDocumentTypeVerified(Invoice_Document_Type.Receipt);

  // const { data: seriesData } = useGetSeriesQuery();

  const initialValues = {
    name: defaultFormValues?.name || '',
    invoice: defaultFormValues?.entries?.find((entry) => entry?.documentType === 'INVOICE')?.validationCode || '',
    debitNote: defaultFormValues?.entries?.find((entry) => entry?.documentType === 'DEBIT_NOTE')?.validationCode || '',
    creditNote:
      defaultFormValues?.entries?.find((entry) => entry?.documentType === 'CREDIT_NOTE')?.validationCode || '',
    simplifiedInvoice:
      defaultFormValues?.entries?.find((entry) => entry?.documentType === 'SIMPLIFIED_INVOICE')?.validationCode || '',
    invoiceReceipt:
      defaultFormValues?.entries?.find((entry) => entry?.documentType === 'INVOICE_RECEIPT')?.validationCode || '',
    receipt: defaultFormValues?.entries?.find((entry) => entry?.documentType === 'RECEIPT')?.validationCode || '',
  };

  const currentYear = new Date().getFullYear();
  const startDate = dayjs(defaultFormValues?.startDate || new Date(currentYear, 0, 1)).format(CALENDAR_FORMAT);
  const endDate = dayjs(defaultFormValues?.endDate || new Date(currentYear, 11, 31)).format(CALENDAR_FORMAT);
  const serieName = defaultFormValues?.name || '';

  const isLocked = defaultFormValues?.managementMode === Serie_Management_Mode.Automatic;

  return (
    <Paper
      p={0}
      shadow="none"
      style={{
        ...style,
        position: 'relative',
        backgroundColor: 'var(--mantine-color-body)',
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
          if (isEditing && updateSerieId) {
            updateSerieMutation({
              variables: {
                updateSerieId,
                serie: {
                  entries: [
                    {
                      documentType: Invoice_Document_Type.Invoice,
                      validationCode: values.invoice,
                      isVerified: isInvoiceDocVerified,
                    },
                    {
                      documentType: Invoice_Document_Type.DebitNote,
                      validationCode: values.debitNote,
                      isVerified: isDebitNoteDocVerified,
                    },
                    {
                      documentType: Invoice_Document_Type.CreditNote,
                      validationCode: values.creditNote,
                      isVerified: isCreditNoteDocVerified,
                    },
                    {
                      documentType: Invoice_Document_Type.SimplifiedInvoice,
                      validationCode: values.simplifiedInvoice,
                      isVerified: isSimplifiedInvoiceDocVerified,
                    },
                    {
                      documentType: Invoice_Document_Type.InvoiceReceipt,
                      validationCode: values.invoiceReceipt,
                      isVerified: isInvoiceReceiptDocVerified,
                    },
                  ],
                },
              },
            });
            return;
          }
          createSerieMutation({
            variables: {
              serie: {
                managementMode: Serie_Management_Mode.Manual,
                tenantId,
                startDate: dayjs(defaultFormValues?.startDate).toDate(),
                endDate: dayjs(defaultFormValues?.endDate).toDate(),
                name: values?.name,
                entries: [
                  {
                    documentType: Invoice_Document_Type.Invoice,
                    validationCode: values.invoice,
                    isVerified: isInvoiceDocVerified,
                  },
                  {
                    documentType: Invoice_Document_Type.DebitNote,
                    validationCode: values.debitNote,
                    isVerified: isDebitNoteDocVerified,
                  },
                  {
                    documentType: Invoice_Document_Type.CreditNote,
                    validationCode: values.creditNote,
                    isVerified: isCreditNoteDocVerified,
                  },
                  {
                    documentType: Invoice_Document_Type.SimplifiedInvoice,
                    validationCode: values.simplifiedInvoice,
                    isVerified: isSimplifiedInvoiceDocVerified,
                  },
                  {
                    documentType: Invoice_Document_Type.InvoiceReceipt,
                    validationCode: values.invoiceReceipt,
                    isVerified: isInvoiceReceiptDocVerified,
                  },
                ],
              },
            },
          });
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form autoComplete="off">
              <LoadingOverlay visible={loading || updateSerieLoading} />

              <Container className="text-center">
                <Title order={3}>{serieName}</Title>
                <Text c="dimmed" size="sm">
                  {startDate} — {endDate}
                </Text>
                <Space h="md" />
                <Title order={5}>{t('label.ATCUD')}</Title>
              </Container>

              {!isEditing && (
                <Group grow mt="md">
                  <FormikInput
                    name="name"
                    label={t('label.name')}
                    placeholder={t('label.name')}
                    error={errors.name && touched.name ? errors.name : null}
                    disabled={isLocked}
                  />
                </Group>
              )}

              <Group grow mt="md">
                <FormikInput
                  name="invoice"
                  label={t('label.invoice')}
                  placeholder={t('label.invoice')}
                  error={errors.invoice && touched.invoice ? errors.invoice : null}
                  disabled={isLocked}
                  rightSection={
                    initialValues.invoice && isInvoiceDocVerified ? (
                      <IconCheck size={20} stroke={2} className="text-medium-turquoise" />
                    ) : null
                  }
                />
              </Group>

              <Group grow mt="md">
                <FormikInput
                  name="debitNote"
                  label={t('label.debitNote')}
                  placeholder={t('label.debitNote')}
                  error={errors.debitNote && touched.debitNote ? errors.debitNote : null}
                  disabled={isLocked}
                  rightSection={
                    initialValues.debitNote && isDebitNoteDocVerified ? (
                      <IconCheck size={20} stroke={2} className="text-medium-turquoise" />
                    ) : null
                  }
                />
              </Group>

              <Group grow mt="md">
                <FormikInput
                  name="creditNote"
                  label={t('label.creditNote')}
                  placeholder={t('label.creditNote')}
                  error={errors.creditNote && touched.creditNote ? errors.creditNote : null}
                  disabled={isLocked}
                  rightSection={
                    initialValues.creditNote && isCreditNoteDocVerified ? (
                      <IconCheck size={20} stroke={2} className="text-medium-turquoise" />
                    ) : null
                  }
                />
              </Group>

              <Group grow mt="md">
                <FormikInput
                  name="simplifiedInvoice"
                  label={t('label.simplifiedInvoice')}
                  placeholder={t('label.simplifiedInvoice')}
                  error={errors.simplifiedInvoice && touched.simplifiedInvoice ? errors.simplifiedInvoice : null}
                  disabled={isLocked}
                  rightSection={
                    initialValues.simplifiedInvoice && isSimplifiedInvoiceDocVerified ? (
                      <IconCheck size={20} stroke={2} className="text-medium-turquoise" />
                    ) : null
                  }
                />
              </Group>

              <Group grow mt="md">
                <FormikInput
                  name="invoiceReceipt"
                  label={t('label.invoiceReceipt')}
                  placeholder={t('label.invoiceReceipt')}
                  error={errors.invoiceReceipt && touched.invoiceReceipt ? errors.invoiceReceipt : null}
                  disabled={isLocked}
                  rightSection={
                    initialValues.invoiceReceipt && isInvoiceReceiptDocVerified ? (
                      <IconCheck size={20} stroke={2} className="text-medium-turquoise" />
                    ) : null
                  }
                />
              </Group>

              <Group grow mt="md">
                <FormikInput
                  name="receipt"
                  label={t('label.receipt')}
                  placeholder={t('label.receipt')}
                  error={errors.receipt && touched.receipt ? errors.receipt : null}
                  disabled={isLocked}
                  rightSection={
                    initialValues.receipt && isReceiptDocVerified ? (
                      <IconCheck size={20} stroke={2} className="text-medium-turquoise" />
                    ) : null
                  }
                />
              </Group>

              {(error || updateSerieError) && (
                <Text c="red" size="sm" mt="sm">
                  <pre>{JSON.stringify(error || updateSerieError)}</pre>
                </Text>
              )}

              {!isLocked && (
                <Button type="submit" size="m" variant="primary" fullWidth className="mt-[32px]">
                  {isEditing ? t('button.label.save') : t('button.label.create')}
                </Button>
              )}

              {isLocked && <div style={{ marginTop: 28 }} />}
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default CreateNewSerieModalForm;
