import { LoadingOverlay, Stack, Text } from '@mantine/core';
import { showErrorToast, showSuccessToast } from '@/components/Toast';
import { useUpdateTenantMutation } from '@/graphql/operations';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { Form, Formik } from 'formik';
import { FormikRadioButtonGroup } from '@/components/FormikWrapped/new';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ButtonVariants';

export type CommunicationMethodFormProps = {
  onSubmitSuccess?: () => void;
};

export const CommunicationMethodForm = ({ onSubmitSuccess }: CommunicationMethodFormProps) => {
  const { t } = useTranslation();
  const tenant = useReactiveVar(tenantVar);

  const [updateTenantMutation, { loading: updateTenantMutationLoading }] = useUpdateTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error:', message: error.message });
      return;
    },
  });

  if (!tenant) {
    return <LoadingOverlay visible />;
  }

  const credentialsVerified = !!tenant.credentials?.verified;
  const credentialsHaveUsername = !!tenant.credentials?.username;
  const noCredentials = !credentialsVerified && !credentialsHaveUsername;

  const initialValues = {
    manageInvoices: tenant.isEnabledSyncWithAT || noCredentials ? 'auto' : 'manual',
  };

  return (
    <>
      <LoadingOverlay visible={updateTenantMutationLoading} />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const updatedTenant = await updateTenantMutation({
            variables: {
              updateTenantId: tenant.id || '',
              tenant: {
                isEnabledSyncWithAT: values.manageInvoices === 'auto',
              },
            },
          });

          if (!updatedTenant.data) {
            return;
          }

          tenantVar(updatedTenant.data?.updateTenant);

          showSuccessToast({
            title: t('label.settingsUpdated'),
            message: t('label.settingsUpdatedSuccessfully'),
          });

          resetForm({ values });

          if (onSubmitSuccess) {
            onSubmitSuccess();
          }
        }}
      >
        <Form autoComplete="off" className="pt-2">
          <Stack gap={1}>
            <FormikRadioButtonGroup
              name="manageInvoices"
              data={[
                {
                  value: 'auto',
                  title: t('label.autoRecommended'),
                  description: t('label.sediesAndDocumentsWillSyncWithAT'),
                },
                {
                  value: 'manual',
                  title: t('label.manual'),
                  description: (
                    <Text className="text-[11px] leading-[130%]">
                      {t('label.seriesWillSynchronizeWithAT')}
                      <br />
                      {t('label.youCanExportSAFTPTFile')}
                    </Text>
                  ),
                },
              ]}
            />

            <Button type="submit" fullWidth variant="primary" className="mt-6" size="m">
              {t('button.label.save')}
            </Button>
          </Stack>
        </Form>
      </Formik>
    </>
  );
};
