import { LoadingOverlay, Text, UnstyledButton } from '@mantine/core';
import { useTranslation, getLanguageFromLocalStorage } from '@/components/I18N';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { useDisclosure } from '@mantine/hooks';
import { Tenant_Type } from '@/graphql-types';
import { FormikInput } from '@/components/FormikWrapped/new';
import { FormikInputProps } from '@/components/FormikWrapped/new/FormikInput';
import compact from 'lodash/compact';
import { cn, OTHER_SUBTYPE_LOCALIZED_ID } from '@/utils';

type BusinessGeneralSettingsProps = {
  editMode: ReturnType<typeof useDisclosure>[1];
};

export const BusinessGeneralSettingsPreview = ({ editMode }: BusinessGeneralSettingsProps) => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);

  if (!tenantObject) return <LoadingOverlay visible />;

  const getTenantSubtypeName = () => {
    if (tenantObject.subtype === OTHER_SUBTYPE_LOCALIZED_ID && tenantObject.subtypeFreeInput) {
      return tenantObject.subtypeFreeInput;
    }

    if (tenantObject.subtypeData?.localizations.length) {
      return tenantObject?.subtypeData.localizations?.find((sub) => sub.locale === getLanguageFromLocalStorage())?.name;
    }
    return '';
  };

  const nifOrNipcLabel = tenantObject.type === Tenant_Type.Company ? t('label.nipc') : t('label.nif');
  const previewFields: FormikInputProps[] = compact([
    {
      name: 'taxPayerNumber',
      label: nifOrNipcLabel,
      value: `${tenantObject.country} ${tenantObject.taxPayerNumber}`,
    },
    {
      name: 'name',
      label: t('label.name'),
      value: tenantObject.name,
    },
    {
      name: 'commercialName',
      label: cn(tenantObject.type === Tenant_Type.Company ? t('label.tradeName') : t('label.shortName')),
      value: tenantObject.commercialName,
    },
    tenantObject.type === Tenant_Type.Individual && {
      name: 'typeOfBusiness',
      label: t('label.typeOfActivity'),
      value: getTenantSubtypeName(),
    },
    tenantObject.type === Tenant_Type.Company && {
      name: 'cae',
      label: `${t('label.cae')}`,
      value: tenantObject.cae?.join(', ') || '—',
    },
    {
      name: 'address',
      label: t('label.address'),
      value: `${tenantObject.address}, ${tenantObject.city}, ${tenantObject.postalCode}`,
    },
  ]);

  return (
    <>
      <div className="flex justify-between pb-4">
        <Text fw={600} className="text-[20px] text-dark-resin">
          {t('label.generalInfo')}
        </Text>
        <UnstyledButton
          variant="transparent"
          className="text-[17px] font-semibold text-moroccan-blue"
          onClick={() => editMode.open()}
        >
          {t('button.label.edit')}
        </UnstyledButton>
      </div>
      <div>
        {previewFields.map((previewField) => (
          <FormikInput
            key={previewField.name}
            className="pb-3 last:pb-0"
            controlled
            readOnly
            data-preview
            {...previewField}
          />
        ))}
      </div>
      {/* <div className="divide-y divide-solid divide-silver-vanilla">
        {previewFields.map((previewField) => (
          <div key={previewField.name} className="flex justify-between py-4 last:pb-0">
            <Text className="text-sm font-semibold text-dark-bronze">{previewField.label}</Text>
            <Text className="max-w-[60%] text-end text-[17px] font-normal text-black-magic">{previewField.info}</Text>
          </div>
        ))}
      </div> */}
    </>
  );
};
