import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { validateIBAN } from '@/utils/iban';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    iban: Yup.string()
      .nullable()
      .trim()
      .test('iban', t('validation.invalidIBANFormat'), (iban) => validateIBAN(iban || '')),
  });
