import { Grid } from '@mantine/core';
import { InvoicingCredentialsSettings } from './Credentials';
import { InvoiceSeriesSettings } from './Series/InvoicingSeriesSettings';
// import { BusinessGeneralSettings } from './General/BusinessGeneralSettings';
// import { BusinessPersonalizationSettings } from './Personalization/BusinessPersonalizationSettings';

export function Component() {
  return (
    <Grid justify="space-between" align="flex-start" className="mt-2 sm:mt-[34px]">
      <Grid.Col span={{ base: 12, lg: 6 }}>
        <InvoicingCredentialsSettings />
      </Grid.Col>
      <Grid.Col span={{ base: 12, lg: 'auto' }}>
        <InvoiceSeriesSettings />
      </Grid.Col>
    </Grid>
  );
}

Component.displayName = 'InvoicingSettingsSection';
