import { Grid } from '@mantine/core';
import { BusinessGeneralSettings } from './General/BusinessGeneralSettings';
export function Component() {
  return (
    <Grid justify="space-between" align="flex-start">
      <Grid.Col span={{ base: 12, lg: 6 }}>
        <BusinessGeneralSettings />
      </Grid.Col>
    </Grid>
  );
}

Component.displayName = 'GeneralSettingsSection';
