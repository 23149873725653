import { CURRENT_TENANT_STORAGE_KEY } from '@/config/constants';
import { captureExceptionEvent } from '@/config/sentry/initSentry';
import { getAccessToken } from '@/utils';

type UploadCropResult = {
  success: boolean;
  message: string;
  fileName: string;
  fileUrl: string;
  fileId: string;
};

const url = import.meta.env.MODE === 'development' ? 'http://localhost:2000/upload' : '/upload';

const fileService = (() => {
  const uploadFile = async (croppedFile: File) => {
    const token = getAccessToken();

    const formData = new FormData();
    formData.append('file', croppedFile, croppedFile.name);

    try {
      const currentTenantId = localStorage.getItem(CURRENT_TENANT_STORAGE_KEY);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-tenant-id': currentTenantId || '',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result: UploadCropResult = await response.json();

      return result;
    } catch (error) {
      captureExceptionEvent(error, { location: 'fileService.uploadFile' });

      return {
        success: false,
        message: (error as Error).message,
        fileId: '',
      };
    }
  };

  return {
    uploadFile,
  };
})();

export default fileService;
