import { LoadingOverlay } from '@mantine/core';
import { useTranslation } from '@/components/I18N';
import { Formik } from 'formik';
import { useUpdateTenantMutation } from '@/graphql-operations';
import { showErrorToast, showSuccessToast } from '@/components/Toast';
import { validationSchema } from './validationSchema';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { useDisclosure } from '@mantine/hooks';
import { BusinessGeneralSettingsPreview } from './BusinessGeneralSettingsPreview';
import { BusinessGeneralSettingsEdit } from './BusinessGeneralSettingsEdit';

export const BusinessGeneralSettings = () => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);
  const [isEditMode, editMode] = useDisclosure(false);

  const [updateTenantMutation, { loading: updateTenantMutationLoading }] = useUpdateTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error:', message: error.message });
      return;
    },
  });

  if (!tenantObject) return <LoadingOverlay visible />;

  const initialValues = {
    name: tenantObject.name || '',
    commercialName: tenantObject.commercialName || '',
    taxPayerNumber: tenantObject.taxPayerNumber,
    territory: tenantObject.territory,
    country: tenantObject.country,
    city: tenantObject.city === 'Desconhecido' ? '' : tenantObject.city,
    address: tenantObject.address === 'Desconhecido' ? '' : tenantObject.address,
    postalCode: tenantObject.postalCode,
    type: tenantObject.type,
    subtype: tenantObject.subtype,
    subtypeFreeInput: tenantObject.subtypeFreeInput?.trim(),
  };

  return (
    <>
      <LoadingOverlay visible={updateTenantMutationLoading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const updatedTenant = await updateTenantMutation({
            variables: {
              updateTenantId: tenantObject.id || '',
              tenant: {
                name: values.name.trim(),
                commercialName: values.commercialName?.trim(),
                territory: values.territory,
                city: values.city,
                address: values.address,
                postalCode: values.postalCode,
                subtype: values.subtype,
                subtypeFreeInput: values.subtypeFreeInput,
              },
            },
          });

          if (updatedTenant.errors) {
            // TODO: smth is wrong with types, message exists
            // showErrorToast({ title: 'Error:', message: updatedTenant.errors.message });
          } else if (updatedTenant.data?.updateTenant) {
            showSuccessToast({
              title: t('label.settingsUpdated'),
              message: t('label.settingsUpdatedSuccessfully'),
            });

            tenantVar(updatedTenant.data?.updateTenant);

            setSubmitting(false);
            resetForm({ values });
            editMode.close();
          }
        }}
      >
        <div className="mt-2 rounded-3xl bg-white-vanilla p-5 sm:mt-[34px]">
          {isEditMode ? (
            <BusinessGeneralSettingsEdit editMode={editMode} />
          ) : (
            <BusinessGeneralSettingsPreview editMode={editMode} />
          )}
        </div>
      </Formik>
    </>
  );
};
