import * as Yup from 'yup';
import { ONBOARDING_COUNTRIES, COMPANY_NAME_MAX_LENGTH, PT_POSTAL_CODE_REGEX } from '@/utils';
import { TFunction } from 'i18next';
import { nifPattern, validateNifControlDigit } from '@/utils/nifNipc';
import { Tenant_Type } from '@/graphql/types';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('validation.nameRequired'))
      .max(COMPANY_NAME_MAX_LENGTH, t('validation.nameLimitationsSymbols', { length: COMPANY_NAME_MAX_LENGTH })),
    commercialName: Yup.string()
      .trim()
      .max(60, Tenant_Type.Company ? t('validation.tradeNameTooLong') : t('validation.shortNameTooLong')),
    taxPayerNumber: Yup.string()
      .required(t('validation.nifRequired'))
      .when('country', {
        is: ONBOARDING_COUNTRIES.PT,
        then: (schema) =>
          schema
            .matches(nifPattern, t('validation.invalidNifFormat'))
            .test('nif-control-digit', validateNifControlDigit),
        otherwise: (schema) => schema.max(30, t('validation.nifTooLong')).trim(),
      }),
    territory: Yup.string().required(t('validation.territoryRequired')).trim(),
    city: Yup.string().required(t('validation.cityRequired')).trim().max(50, t('validation.cityTooLong')),
    address: Yup.string().required(t('validation.addressRequired')).trim().max(210, t('validation.addressTooLong')),
    postalCode: Yup.string()
      .required(t('validation.postalCodeRequired'))
      .matches(PT_POSTAL_CODE_REGEX, t('validation.postalCodeFormat')),
  });
