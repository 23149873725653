import { Button, LoadingOverlay, Stack, Text, UnstyledButton } from '@mantine/core';
import { useTranslation, getLanguageFromLocalStorage, Language } from '@/components/I18N';
import { FormikInput } from '@/components/FormikWrapped/new';
import { Form, useFormikContext } from 'formik';
import { useGetTenantSubtypeCategoriesLazyQuery } from '@/graphql-operations';
import { showErrorToast } from '@/components/Toast';
import { SubmitButton } from '../../SubmitButton';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import SelfEmployedModal from '../../../GetStartedForms/Step1/parts/SelfEmployedModal';
import { useDisclosure } from '@mantine/hooks';
import IconArrowLeftBlack from '@/assets/icons/IconArrowLeftBlack.svg?react';
import { Tenant, Tenant_Type, TenantSubtypeCategory } from '@/graphql-types';
import { OTHER_SUBTYPE_LOCALIZED_ID } from '@/utils';

type BusinessGeneralSettingsProps = {
  editMode: ReturnType<typeof useDisclosure>[1];
};

export const BusinessGeneralSettingsEdit = ({ editMode }: BusinessGeneralSettingsProps) => {
  const { t } = useTranslation();
  const tenantObject = useReactiveVar(tenantVar);
  const [isSelfEmplModalOpened, selfEmplModal] = useDisclosure(false);
  const { values, isSubmitting, resetForm } = useFormikContext<Partial<Tenant>>();

  const [fetchSubtypeCategories, { data: subtypesData, loading: getSubtypeCategoriesLoading }] =
    useGetTenantSubtypeCategoriesLazyQuery({
      onError: (error) => {
        showErrorToast({ title: 'Error', message: error.message });
      },
    });

  if (!tenantObject) return <LoadingOverlay visible />;

  const getSelfEmployedSubtitle = (subtype: string, subtypeFreeInput?: string) => {
    if (tenantObject?.subtypeData?.localizations.length && subtype === tenantObject?.subtype) {
      // If TenantObject has subtypeData and subtype is other and user has entered a custom subtype
      if (subtype === OTHER_SUBTYPE_LOCALIZED_ID && subtypeFreeInput) {
        return subtypeFreeInput;
      }

      return tenantObject?.subtypeData.localizations.find((sub) => sub.locale === getLanguageFromLocalStorage())?.name;
    }
    if (!subtype || !subtypesData) return t('placeholder.workerEntrepreneur');

    // If subtype is other and user has entered a custom subtype
    if (subtype === OTHER_SUBTYPE_LOCALIZED_ID && subtypeFreeInput) {
      return subtypeFreeInput;
    }
    return findTenantSubtypeName(subtypesData.tenantSubtypeCategories, getLanguageFromLocalStorage(), subtype);
  };

  const findTenantSubtypeName = (subTypes: TenantSubtypeCategory[], locale: Language, tenantSubtypeId: string) => {
    if (!subTypes) return '';

    const selectedSubtype =
      subTypes
        .map((subType) => subType.localizations.find((localization) => localization.locale === locale))
        .filter((localization) => localization)
        .flatMap((localization) => localization?.tenantSubtypes)
        .find((tenantSubtype) => tenantSubtype?.id === tenantSubtypeId) || null;

    return selectedSubtype?.name || '';
  };

  const nifOrNipcLabel = tenantObject.type === Tenant_Type.Company ? t('label.nipc') : t('label.nif');

  return (
    <Form autoComplete="off">
      <LoadingOverlay visible={getSubtypeCategoriesLoading} />

      <div className="flex justify-between pb-4">
        <Text fw={600} className="text-[20px] text-dark-resin">
          {t('label.generalInfo')}
        </Text>

        <UnstyledButton
          variant="transparent"
          className="text-[17px] font-semibold text-moroccan-blue"
          onClick={() => {
            resetForm();
            editMode.close();
          }}
        >
          {t('button.label.cancel')}
        </UnstyledButton>
      </div>

      <Stack gap={12}>
        <FormikInput
          name="taxPayerNumber"
          label={nifOrNipcLabel}
          value={`${tenantObject.country} ${values.taxPayerNumber}`}
          disabled
        />

        <FormikInput name="name" label={t('label.name')} disabled={tenantObject.type === Tenant_Type.Company} />

        <FormikInput
          name="commercialName"
          label={tenantObject.type === Tenant_Type.Company ? t('label.tradeName') : t('label.shortName')}
          placeholder={
            tenantObject.type === Tenant_Type.Company
              ? t('placeholder.enterTradeName')
              : t('placeholder.enterShortName')
          }
        />

        {tenantObject.type === Tenant_Type.Company && (
          <FormikInput
            name="cae"
            label={`${t('label.cae')}`}
            value={tenantObject.cae?.join(', ') || '—'}
            disabled
            controlled
          />
        )}

        {tenantObject.type === Tenant_Type.Individual && (
          <Button
            variant="outline"
            justify="space-between"
            className="h-14 w-full rounded-2xl border border-satin-gray bg-white pl-[14px] hover:bg-zinc-50 xs:flex-grow"
            onClick={async () => {
              await fetchSubtypeCategories();
              selfEmplModal.open();
            }}
            rightSection={<IconArrowLeftBlack />}
          >
            <div className="text-start">
              <Text className="ml-[1px] mt-0.5 text-[11px] font-medium text-black-burgundy">
                {t('label.typeOfActivity')}
              </Text>
              <Text>{getSelfEmployedSubtitle(values.subtype!, values.subtypeFreeInput)}</Text>
            </div>
          </Button>
        )}

        <FormikInput name="address" label={t('label.addressDetails')} placeholder={t('placeholder.enterYourAddress')} />
        <FormikInput name="city" label={t('label.city')} placeholder={t('placeholder.enterYourCity')} />
        <FormikInput
          name="postalCode"
          mask="0000-000"
          inputMode="numeric"
          label={t('label.postalCode')}
          placeholder={t('placeholder.enterYourPostalCode')}
        />
      </Stack>

      <SubmitButton
        loading={isSubmitting}
        className="mt-[24px] h-12 w-full rounded-[20px] bg-light-brand text-[17px] font-semibold hover:bg-light-orange"
      />

      {isSelfEmplModalOpened && (
        <SelfEmployedModal
          selfEmplModalOpened={isSelfEmplModalOpened}
          closeSelfEmplModal={selfEmplModal.close}
          subtypesList={subtypesData?.tenantSubtypeCategories || []}
        />
      )}
    </Form>
  );
};
