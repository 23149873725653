import { Stack, Text, LoadingOverlay } from '@mantine/core';
import { Form } from 'formik';
import { Trans, useTranslation } from '@/components/I18N';

import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { useFormikContext } from 'formik';
import { FormikInput, FormikRadioButtonGroup, FormikCheckbox } from '@/components/FormikWrapped/new';
import { Button } from '@/components/ButtonVariants';

export type ConnectATMainLevelFormFields = {
  username: string;
  password: string;
  manageInvoices: 'auto' | 'manual';
  tAndC: boolean;
};

export const ConnectATMainLevelForm = () => {
  const { t } = useTranslation();
  const tenant = useReactiveVar(tenantVar);
  const { isSubmitting } = useFormikContext<ConnectATMainLevelFormFields>();

  if (!tenant) {
    return <LoadingOverlay visible />;
  }

  return (
    <Form autoComplete="off">
      <Stack gap={8}>
        <div className="text-[14px]">
          {t('label.enterPasswordForATAccount')}
          <div>
            <Trans
              i18nKey="label.notStoreThisData"
              components={{
                a1: <span className="font-semibold text-black-magic"></span>,
                a2: <span className="font-normal text-light-black-magic"></span>,
              }}
            />
          </div>
        </div>
        <FormikInput
          name="username"
          inputMode="numeric"
          label={`${t('label.mainATUserID')} (${t('label.nif')})`}
          readOnly
          data-preview
        />

        <FormikInput
          type="password"
          name="password"
          label={t('label.mainATUserPassword')}
          placeholder={t('placeholder.enterMainUserPassword')}
        />

        <FormikCheckbox name="tAndC" label={t('label.allowToCreateSubUser')} className="mt-2" />
      </Stack>

      <Stack gap={1}>
        <Text className="pb-3 pt-6 text-[17px] font-semibold">{t('label.chooseCommunicationMethod')}</Text>

        <FormikRadioButtonGroup
          name="manageInvoices"
          data={[
            {
              value: 'auto',
              title: t('label.autoRecommended'),
              description: t('label.sediesAndDocumentsWillSyncWithAT'),
            },
            {
              value: 'manual',
              title: t('label.manual'),
              description: (
                <Text className="text-[11px] leading-[130%]">
                  {t('label.seriesWillSynchronizeWithAT')}
                  <br />
                  {t('label.youCanExportSAFTPTFile')}
                </Text>
              ),
            },
          ]}
        />

        <Button type="submit" size="m" variant="primary" fullWidth className="mt-6" loading={isSubmitting}>
          {t('button.label.save')}
        </Button>
      </Stack>
    </Form>
  );
};
