import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { LoadingOverlay } from '@mantine/core';
import { ConnectWithATOptions } from './ConnectWithATOptions';
import { ExistingATConnection } from './ExistingATConnection';

export const InvoicingCredentialsSettings = () => {
  const tenant = useReactiveVar(tenantVar);

  if (!tenant) {
    return <LoadingOverlay visible />;
  }

  const credentialsVerified = !!tenant.credentials?.verified;
  const credentialsHaveUsername = !!tenant.credentials?.username;
  const noCredentials = !credentialsVerified && !credentialsHaveUsername;

  if (noCredentials) {
    return <ConnectWithATOptions />;
  }

  return <ExistingATConnection />;
};
