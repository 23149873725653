import { TFunction } from 'i18next';
import * as Yup from 'yup';

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    invoice: Yup.string().required(t('validation.invoiceRequired')),
    debitNote: Yup.string().required(t('validation.debitNoteRequired')),
    creditNote: Yup.string().required(t('validation.creditNoteRequired')),
    simplifiedInvoice: Yup.string().required(t('validation.simplifiedInvoiceRequired')),
    invoiceReceipt: Yup.string().required(t('validation.invoiceReceiptRequired')),
    name: Yup.string().required(t('validation.nameRequired')),
  });

export default validationSchema;
