import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    username: Yup.string()
      .max(4, t('validation.usernameTooLong'))
      .required(t('validation.loginRequired'))
      .trim()
      .max(4, t('validation.usernameTooLong')),
    password: Yup.string()
      .required(t('validation.passwordRequired'))
      .trim()
      .min(8, t('validation.passwordLimitationsSymbolsMin'))
      .max(16, t('validation.passwordLimitationsSymbolsMax')),
  });
