import { Stack, Text, Anchor } from '@mantine/core';
import IconAlertSmallWithYellowBg from '@/assets/icons/IconAlertSmallWithYellowBg.svg?react';
import { useDisclosure } from '@mantine/hooks';
import CustomModal from '@/components/CustomModal';
import { ConnectATSubuserLevel } from './SubuserLevelForm';
import { ConnectATMainLevel } from './MainLevelForm';
import { getWebATInstructionUrl } from '@/utils';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ButtonVariants';

export type ConnectWithATOptionsProps = {
  onConnectionAdded?: () => void;
};

export const ConnectWithATOptions = ({ onConnectionAdded }: ConnectWithATOptionsProps) => {
  const { t } = useTranslation();
  const [mainLevelAccessModalOpen, mainLevelAccessModal] = useDisclosure(false);
  const [subuserLevelAccessModalOpen, subuserLevelAccessModal] = useDisclosure(false);

  const handleConnectionAdded = () => {
    if (mainLevelAccessModalOpen) {
      mainLevelAccessModal.close();
    }

    if (subuserLevelAccessModalOpen) {
      subuserLevelAccessModal.close();
    }

    if (onConnectionAdded) {
      onConnectionAdded();
    }
  };

  return (
    <>
      <Stack gap="10px">
        <div className="flex gap-3 rounded-[20px] bg-light-lemon p-4">
          <IconAlertSmallWithYellowBg />
          <Text className="my-auto text-[14px] font-normal text-black">{t('label.youMustConnectWithAT')}</Text>
        </div>
        <div className="rounded-3xl bg-white-vanilla p-4">
          <Text className="pb-2 text-[14px] font-normal text-deep-graphite">{t('label.provideYourAccessToAT')}</Text>
          <Stack gap={1}>
            <div>
              <Button fullWidth size="m" variant="primary" onClick={mainLevelAccessModal.open}>
                {t('button.label.createSubUser')}
              </Button>
              <Text className="my-4 text-center text-[14px] font-semibold text-bordo-black">{t('label.or')}</Text>
              <Text className="pb-2 text-[14px] font-normal text-deep-graphite">
                {t('label.createSubUserAndEnterDetails')}
              </Text>
              <Button fullWidth variant="ocean" size="m" onClick={subuserLevelAccessModal.open}>
                <Text className="text-[17px] font-bold text-white">{t('button.label.enterSubUser')}</Text>
              </Button>
            </div>
            <Anchor
              className="pt-2 text-[14px] font-semibold text-moroccan-day"
              href={getWebATInstructionUrl()}
              target="_blank"
            >
              {t('button.label.needHelp')} {t('button.label.howToCreateSubUserOnAT')}
            </Anchor>
          </Stack>
        </div>
      </Stack>
      {mainLevelAccessModalOpen && (
        <CustomModal
          title={t('label.createUserAutomatically')}
          isOpened={mainLevelAccessModalOpen}
          handleClose={mainLevelAccessModal.close}
          size={480}
        >
          <ConnectATMainLevel onSubmitSuccess={handleConnectionAdded} />
        </CustomModal>
      )}
      {subuserLevelAccessModalOpen && (
        <CustomModal
          title={t('label.enterSubUserDataFromAT')}
          isOpened={subuserLevelAccessModalOpen}
          handleClose={subuserLevelAccessModal.close}
          size={480}
        >
          <ConnectATSubuserLevel onSubmitSuccess={handleConnectionAdded} />
        </CustomModal>
      )}
    </>
  );
};
