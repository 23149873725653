import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { ActionIcon, Text, UnstyledButton } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useTranslation } from '@/components/I18N';
import CustomModal from '@/components/CustomModal';
import { useDisclosure } from '@mantine/hooks';
import IconTrashCanDark from '@/assets/icons/IconTrashCanDark.svg?react';
import ImageCropper from '@/components/ImageCropper';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { fileService } from '@/services';
import { showErrorToast } from '@/components/Toast';
import { useUpdateTenantMutation } from '@/graphql-operations';

export const ASPECT_RATIO = 1;

const LogoUpload = () => {
  const tenantObject = useReactiveVar(tenantVar);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImageData, setUploadedImageData] = useState({
    src: '',
    originalFileName: '',
  });

  const [cropModalOpened, { open: cropModalOpen, close: cropModalClose }] = useDisclosure(false);
  const { t } = useTranslation();

  const [updateTenantMutation, { loading: updateTenantMutationLoading }] = useUpdateTenantMutation({
    onError: (error) => {
      showErrorToast({ title: 'Error:', message: error.message });
      return;
    },
  });

  const handleDrop = (files: File[]) => {
    setError(undefined);

    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImageData({
        src: reader.result?.toString() || '',
        originalFileName: files[0].name,
      });
    };

    reader.readAsDataURL(files[0]);
    cropModalOpen();
  };

  const updateTenant = async (logoId: string | null) => {
    const updatedTenant = await updateTenantMutation({
      variables: {
        updateTenantId: tenantObject!.id,
        tenant: {
          customization: {
            invoicing: {
              // @ts-expect-error: logoFileId can be null (to remove file)
              logoFileId: logoId,
            },
          },
        },
      },
    });

    tenantVar(updatedTenant.data?.updateTenant);
  };

  const handleCroppedFileSave = async (croppedFile: File) => {
    setError(undefined);
    setIsLoading(true);

    const { success, fileId } = await fileService.uploadFile(croppedFile);

    if (!success) {
      const uploadFailedMessage = t('error.file.uploadFailed');
      setError(uploadFailedMessage);
      setIsLoading(false);

      showErrorToast({ title: t('error.error'), message: uploadFailedMessage });
      return;
    }

    if (success && tenantObject?.id && fileId) {
      await updateTenant(fileId);

      setIsLoading(false);

      cropModalClose();
    }
  };

  const handleDeleteLogo = async () => {
    await updateTenant(null);
  };

  return (
    <>
      <div className="pb-4 pt-6">
        <Text fw={600} className="text-[20px] text-dark-resin">
          {t('label.logo')}
        </Text>
        <span className="text-[14px] font-normal text-bordo-black">{t('label.fileFormat')}</span>
      </div>
      {tenantObject?.customization?.invoicing?.logoFile?.id ? (
        <div className="flex items-center justify-between gap-4 rounded-2xl border border-day-silver bg-white p-4 pr-1">
          <span className="h-[72px] min-w-[72px] rounded-lg">
            <img
              src={tenantObject.customization.invoicing.logoFile?.url}
              alt="Cropped Logo"
              className="h-full w-full rounded-lg object-cover"
            />
          </span>
          <span className="hidden break-all sm:block">{tenantObject.customization.invoicing.logoFile?.name}</span>

          <ActionIcon size="xl" variant="transparent" disabled={updateTenantMutationLoading}>
            <IconTrashCanDark onClick={handleDeleteLogo} />
          </ActionIcon>
        </div>
      ) : (
        <>
          <Dropzone
            onDrop={handleDrop}
            onReject={(files) => {
              const errorMessagesArray = files.map((file) => {
                const error = file.errors[0];

                return error.code ? t(`error.file.${error.code}`, { sizeInMB: 5 }) : error.message;
              });
              setError(errorMessagesArray.join(', '));
            }}
            multiple={false}
            maxSize={5 * 1024 ** 2}
            accept={['image/png', 'image/jpeg']}
          >
            <UnstyledButton className="h-[104px] w-full rounded-[16px] border border-dashed border-scenic-water bg-alice-ocean px-6 text-center">
              <Text className="text-[17px] font-semibold text-moroccan-day">{t('button.label.uploadFile')}</Text>
            </UnstyledButton>
          </Dropzone>

          {error && <Text className="mt-2 text-sunset-orange">{error}</Text>}
        </>
      )}

      {cropModalOpened && !!uploadedImageData.src && (
        <CustomModal isOpened={cropModalOpened} handleClose={cropModalClose} title={t('label.selectArea')}>
          <ImageCropper
            uploadedImageData={uploadedImageData}
            onCroppedFileSave={handleCroppedFileSave}
            isLoading={isLoading}
            aspectRatio={ASPECT_RATIO}
          />
        </CustomModal>
      )}
    </>
  );
};

export default LogoUpload;
