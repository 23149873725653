import { Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { useGetSeriesQuery } from '@/graphql-operations';
import { useTranslation } from '@/components/I18N';
import { Serie, Serie_Management_Mode } from '@/graphql-types';
import { CALENDAR_FORMAT } from '@/utils';
import { IconLock, IconPencil } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useReactiveVar } from '@apollo/client';
import { tenantVar } from '@/config/reactiveVars/tenantVar';
import CustomModal from '@/components/CustomModal';
import CreateNewSerieForm from '@/components/Series/CreateNewSerieForm/CreateNewSerieForm';

function SerieGroup({ serie, handleEditSerie }: { serie: Serie; handleEditSerie: (serie: Serie) => void }) {
  const startDate = dayjs(serie.startDate).format(CALENDAR_FORMAT);
  const endDate = dayjs(serie.endDate).format(CALENDAR_FORMAT);

  const action =
    serie.managementMode === Serie_Management_Mode.Automatic ? (
      <Group className="cursor-pointer items-center text-gray-400">
        <IconLock />
      </Group>
    ) : (
      <Group className="cursor-pointer items-center text-moroccan-blue">
        <IconPencil />
      </Group>
    );

  return (
    <Group
      justify="space-between"
      className="h-16 cursor-pointer rounded-2xl bg-angel-feather"
      pr={16}
      pl={16}
      onClick={() => handleEditSerie(serie)}
    >
      <div>
        <Text fw={500} size="sm" lh={1} c="black-olive">
          {serie.name}
        </Text>
        <Text className="pt-1 text-xs text-gray-400">
          {startDate} — {endDate}
        </Text>
      </div>
      {action}
    </Group>
  );
}

export const InvoiceSeriesSettings = ({
  updateSerieId,
  onSuccessCb,
}: {
  updateSerieId?: string;
  onSuccessCb?: (newSerie: Serie) => void;
}) => {
  const { t } = useTranslation();
  const { data: seriesData, loading: seriesLoading } = useGetSeriesQuery();
  const [opened, { open, close }] = useDisclosure(false);
  const [serieToEdit, setSerieToEdit] = useState<Serie | null>(null);
  const tenant = useReactiveVar(tenantVar);
  const isSmScreen = useMediaQuery('(max-width: 575px)');
  const seriesIndex = updateSerieId ? parseInt(updateSerieId) : undefined;

  const handleOnSuccess = (newSerie: Serie) => {
    if (onSuccessCb) {
      onSuccessCb(newSerie);
    }
    close();
  };

  if (!tenant) {
    return <LoadingOverlay visible />;
  }

  const handleEditSerie = (serie?: Serie) => {
    setSerieToEdit(serie || null);
    open();
  };

  const handleModalClose = () => {
    setSerieToEdit(null);
    close();
  };

  if (!seriesLoading && seriesData?.series?.length) {
    return (
      <div className="rounded-3xl bg-white-vanilla p-5">
        <Text fw={600} className="text-[20px]">
          {t('label.series')}
        </Text>
        <Stack gap={16} mt={16}>
          {seriesData?.series.map((serie) => (
            <SerieGroup key={serie.id} serie={serie} handleEditSerie={handleEditSerie} />
          ))}
          {/* <Group justify="space-between" className="h-8" pr={16} pl={16}>
          <UnstyledButton
            onClick={() => handleEditSerie()}
            className="flex gap-3 align-middle text-moroccan-blue"
          >
            <IconCirclePlus className="w-[20px]" />
            <Text>{t('button.label.addNewSerie')}</Text>
          </UnstyledButton>
        </Group> */}
        </Stack>
        {/* <SubmitButton className="rounded-3xl" /> */}
        {/* <Button onClick={handleCreateSerie}> */}

        {/* </Button> */}
        {opened && (
          <CustomModal isOpened={opened} handleClose={handleModalClose} fullScreen={isSmScreen}>
            <CreateNewSerieForm
              seriesIndex={seriesIndex}
              isEditing={!!serieToEdit}
              onSuccess={handleOnSuccess}
              updateSerieId={serieToEdit?.id}
              defaultFormValues={serieToEdit ? { ...serieToEdit } : undefined}
              tenantId={tenant.id || ''}
            />
          </CustomModal>
        )}
      </div>
    );
  }
};
