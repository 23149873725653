import { FormikInput } from '@/components/FormikWrapped/new';
import { FormikNumberInput } from '@/components/FormikWrapped';
import { Stack, Text, LoadingOverlay, Button as MantineButton } from '@mantine/core';
import { Form } from 'formik';
import IconQuestion from '@/assets/icons/IconQuestion.svg?react';
import IconArrowLeftBlue from '@/assets/icons/IconArrowLeftBlue.svg?react';

import { tenantVar } from '@/config/reactiveVars/tenantVar';
import { useReactiveVar } from '@apollo/client';
import { useFormikContext } from 'formik';
import FormikRadioButtonGroup from '@/components/FormikWrapped/new/FormikRadioButtonGroup';
import { getWebATInstructionUrl } from '@/utils';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ButtonVariants';

export type ConnectATSubuserLevelFormFields = {
  username: string;
  password: string;
  manageInvoices: 'auto' | 'manual';
};

export const ConnectATSubuserLevelForm = () => {
  const { t } = useTranslation();
  const tenant = useReactiveVar(tenantVar);
  const { values, isSubmitting, errors, touched } = useFormikContext<ConnectATSubuserLevelFormFields>();

  if (!tenant) {
    return <LoadingOverlay visible />;
  }

  return (
    <Form autoComplete="off">
      <Stack gap={8}>
        <MantineButton
          component="a"
          variant="default"
          className="h-16 rounded-2xl border-none bg-alice-ocean hover:bg-light-silver-angel"
          // onClick={handleHowToSubuserModal}
          leftSection={<IconQuestion />}
          rightSection={<IconArrowLeftBlue />}
          fullWidth
          justify="space-between"
          classNames={{
            label: 'text-wrap text-left grow',
          }}
          href={getWebATInstructionUrl()}
          target="_blank"
        >
          {t('button.label.needHelp')}
          <br />
          {t('button.label.howToCreateSubUserOnAT')}
        </MantineButton>
        <FormikNumberInput
          hideControls
          name="username"
          inputMode="numeric"
          placeholder={t('placeholder.enterSubUserID')}
          label={t('label.subUserID')}
          leftSection={
            values.username ? (
              <>
                <Text className="mt-5 pl-2 text-[16px] text-black-magic">{tenant.taxPayerNumber}</Text>
                <Text className="pl-1.5 pt-[18px] text-[16px]">/</Text>
              </>
            ) : (
              <>
                <Text className="mt-px pl-2 text-[17px] text-black-magic">{tenant.taxPayerNumber}</Text>
                <Text className="pl-1 text-[17px]">/</Text>
              </>
            )
          }
          leftSectionWidth={120}
          error={errors.username && touched.username ? errors.username : null}
        />

        <FormikInput
          type="password"
          name="password"
          label={t('label.subUserPassword')}
          placeholder={t('placeholder.enterSubUserPassword')}
        />
      </Stack>

      <Stack gap={1}>
        <Text className="pb-3 pt-6 text-[17px] font-semibold">{t('label.chooseCommunicationMethod')}</Text>

        <FormikRadioButtonGroup
          name="manageInvoices"
          data={[
            {
              value: 'auto',
              title: t('label.autoRecommended'),
              description: t('label.sediesAndDocumentsWillSyncWithAT'),
            },
            {
              value: 'manual',
              title: t('label.manual'),
              description: (
                <Text className="text-[11px] leading-[130%]">
                  {t('label.seriesWillSynchronizeWithAT')}
                  <br />
                  {t('label.youCanExportSAFTPTFile')}
                </Text>
              ),
            },
          ]}
        />

        <Button type="submit" fullWidth size="m" variant="primary" className="mt-6" loading={isSubmitting}>
          {t('button.label.save')}
        </Button>
      </Stack>
    </Form>
  );
};
