import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t('validation.passwordRequired'))
      .trim()
      .min(8, t('validation.passwordLimitationsSymbolsMin'))
      .max(16, t('validation.passwordLimitationsSymbolsMax')),
    tAndC: Yup.bool().oneOf([true], t('validation.tAndCRequired')),
  });
