import 'react-image-crop/dist/ReactCrop.css';

import React, { useRef, useState } from 'react';
import ReactCrop, { convertToPixelCrop, Crop, makeAspectCrop, PercentCrop } from 'react-image-crop';

import { captureExceptionEvent } from '@/config/sentry/initSentry';

import { Button } from '../ButtonVariants';
import { useTranslation } from '../I18N';
import { showErrorToast } from '../Toast';

type ImageCropperProps = {
  uploadedImageData: {
    src: string;
    originalFileName: string;
  };
  isLoading?: boolean;
  onCroppedFileSave: (file: File) => void;
  aspectRatio: number;
};

const ImageCropper = ({ uploadedImageData, onCroppedFileSave, isLoading, aspectRatio }: ImageCropperProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<PercentCrop>();
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const { t } = useTranslation();

  const onImageLoad = () => {
    const crop = makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspectRatio,
      100,
      100
    );

    setCrop(crop);
  };

  const onCropCompleteHandler = (crop: Crop) => {
    setCompletedCrop(crop);
  };

  const makeClientCrop = async () => {
    if (imgRef?.current && completedCrop?.width && completedCrop?.height) {
      try {
        const croppedFile = await getCroppedImg(
          imgRef.current,
          convertToPixelCrop(completedCrop, imgRef.current.width, imgRef.current.height),
          uploadedImageData.originalFileName
        );
        onCroppedFileSave(croppedFile);
      } catch (error) {
        captureExceptionEvent(error, { location: 'ImageCropper' });
        showErrorToast({ title: t('error.error'), message: (error as Error).message });
      }
    }
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Crop, fileName: string): Promise<File> => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error(t('error.canNotCrop'));
    }

    // Calculate scaling factors
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Set canvas dimensions to match the cropped area
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    ctx.imageSmoothingQuality = 'high';

    // Draw the cropped portion of the image
    ctx.drawImage(
      image,
      crop.x * scaleX, // source x
      crop.y * scaleY, // source y
      crop.width * scaleX, // source width
      crop.height * scaleY, // source height
      0, // destination x
      0, // destination y
      canvas.width, // destination width
      canvas.height // destination height
    );

    // Get the original file extension
    const fileExtension = fileName.split('.').pop()?.toLowerCase() || 'jpeg';
    const mimeType = fileExtension === 'png' ? 'image/png' : 'image/jpeg';

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error(t('error.canNotCrop')));
            return;
          }

          // Preserve the original filename but update the extension if needed
          const newFileName = fileName.replace(/\.[^/.]+$/, '') + '.' + fileExtension;
          const file = new File([blob], newFileName, { type: mimeType });
          resolve(file);
        },
        mimeType,
        // Use 0.9 quality for JPEG to maintain good quality while reducing file size
        mimeType === 'image/jpeg' ? 0.9 : undefined
      );
    });
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <ReactCrop
        style={{ width: '100%' }}
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        keepSelection
        disabled={isLoading}
        onComplete={onCropCompleteHandler}
      >
        <img ref={imgRef} src={uploadedImageData.src} alt="Upload" onLoad={onImageLoad} />
      </ReactCrop>
      <Button onClick={makeClientCrop} size="m" variant="primary" fullWidth className="px-10 py-5" disabled={isLoading}>
        {t('button.label.save')}
      </Button>
    </div>
  );
};

export default ImageCropper;
